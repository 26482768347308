<style scoped>
.director-table {
  min-width: 50%;
}
table > thead {
  background: #eee;
}
table,
td,
th {
  border: 1px solid rgba(148, 148, 148, 0.556);
  padding: 5px 15px;
}

.director-table {
  border-collapse: collapse;
}
</style>
<template>
  <div>
    <v-card elevation="5">
      <v-card-text class="pa-0 font-weight-bold">
        <div>
          <div class="primary_2 d-flex  white--text font-weight-bold px-2 mb-1">
            <div
              @click="toggleBasicDetailsAccordion()"
              style="width: 75%"
              class="d-flex align-center py-3"
            >
              <div class="text-body-1 font-weight-medium px-3">
                {{ business_accordion_details.title }}
              </div>
            </div>
            <div style="width: 25%" class="d-flex align-center justify-end">
              <v-btn
                class="white--text mr-2"
                small
                outlined
                @click="editBusinessDetail()"
              >
                <v-icon small>mdi-pencil</v-icon>Edit</v-btn
              >
              <v-btn fab small text @click="toggleBasicDetailsAccordion()">
                <v-icon color="white" v-if="business_accordion_details.show">
                  mdi-chevron-up
                </v-icon>
                <v-icon color="white" v-else>
                  mdi-chevron-down
                </v-icon>
              </v-btn>
            </div>
          </div>
          <div v-if="business_accordion_details.show">
            <div
              v-if="
                business_details.entity_type == 'sole_proprietor' ||
                  business_details.entity_type == 'HUF'
              "
              class="pb-3 px-3 pt-3"
            >
              <v-row class="pl-3 pt-0">
                <v-col cols="4">
                  <p class="field-title">
                    Name of Co-Applicant
                  </p>
                  <p class="field-content">
                    <span v-if="business_details.co_applicant_details">
                      {{
                        this.business_details.co_applicant_details
                          .co_applicant_name
                      }}
                    </span>
                    <span v-else>-</span>
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="field-title">
                    Co-Applicant Father's Name
                  </p>
                  <p class="field-content">
                    <span v-if="business_details">
                      {{
                        this.business_details.co_applicant_details
                          .co_applicant_fathers_name
                      }}
                    </span>
                    <span v-else>-</span>
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="field-title">
                    Co-Applicant Mother's Name
                  </p>
                  <p class="field-content">
                    <span v-if="business_details.co_applicant_details">
                      {{
                        this.business_details.co_applicant_details
                          .co_applicant_mothers_name
                      }}
                    </span>
                    <span v-else>-</span>
                  </p>
                </v-col>
              </v-row>
              <v-row class="pl-3 pt-0">
                <v-col cols="4">
                  <p class="field-title">
                    Date of Birth
                  </p>
                  <p class="field-content">
                    {{
                      this.business_details.co_applicant_details
                        .co_applicant_dob
                    }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="field-title">
                    Relation
                  </p>
                  <p class="field-content">
                    {{ this.business_details.co_applicant_details.relation }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="field-title">
                    Firm Name
                  </p>
                  <p class="field-content">
                    {{ this.business_details.co_applicant_details.firm_name }}
                  </p>
                </v-col>
              </v-row>
              <v-row v-if="business_details.co_applicant_details">
                <v-col cols="12">
                  <v-row class=" ma-0 document-row">
                    <v-col cols="4" class="d-flex align-center">
                      <div class="field-title text-body-2">
                        Aadhaar Front & Back
                      </div>
                    </v-col>
                    <v-col class="d-flex justify-end align-center">
                      <v-btn
                        :disabled="
                          !business_details.co_applicant_details
                            .aadhar_card_front_and_back
                        "
                        @click="
                          isImageDocument(
                            business_details.co_applicant_details
                              .aadhar_card_front_and_back
                          )
                            ? previewImage(
                                business_details.co_applicant_details
                                  .aadhar_card_front_and_back,
                                'Aadhaar Front & Back'
                              )
                            : viewDocument(
                                business_details.co_applicant_details
                                  .aadhar_card_front_and_back
                              )
                        "
                        small
                        text
                        class="indigo lighten-5 py-4"
                      >
                        <v-icon color="deep-purple darken-4"
                          >mdi-eye-outline</v-icon
                        >
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row class=" ma-0 document-row">
                    <v-col cols="4" class="d-flex align-center">
                      <div class="field-title text-body-2">
                        Aadhaar Front
                      </div>
                    </v-col>
                    <v-col class="d-flex justify-end align-center">
                      <v-btn
                        :disabled="
                          !business_details.co_applicant_details
                            .aadhar_card_front_file
                        "
                        @click="
                          isImageDocument(
                            business_details.co_applicant_details
                              .aadhar_card_front_file
                          )
                            ? previewImage(
                                business_details.co_applicant_details
                                  .aadhar_card_front_file,
                                'Aadhaar Front'
                              )
                            : viewDocument(
                                business_details.co_applicant_details
                                  .aadhar_card_front_file
                              )
                        "
                        small
                        text
                        class="indigo lighten-5 py-4"
                      >
                        <v-icon color="deep-purple darken-4"
                          >mdi-eye-outline</v-icon
                        >
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row class=" ma-0 document-row">
                    <v-col cols="4" class="d-flex align-center">
                      <div class="field-title text-body-2">
                        Aadhaar Back
                      </div>
                    </v-col>
                    <v-col class="d-flex justify-end align-center">
                      <v-btn
                        :disabled="
                          !business_details.co_applicant_details
                            .aadhar_card_back_file
                        "
                        @click="
                          isImageDocument(
                            business_details.co_applicant_details
                              .aadhar_card_back_file
                          )
                            ? previewImage(
                                business_details.co_applicant_details
                                  .aadhar_card_back_file,
                                'Aadhaar Front'
                              )
                            : viewDocument(
                                business_details.co_applicant_details
                                  .aadhar_card_back_file
                              )
                        "
                        small
                        text
                        class="indigo lighten-5 py-4"
                      >
                        <v-icon color="deep-purple darken-4"
                          >mdi-eye-outline</v-icon
                        >
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row class=" ma-0 document-row">
                    <v-col cols="4" class="d-flex align-center">
                      <div class="field-title text-body-2">
                        PAN Card
                      </div>
                    </v-col>
                    <v-col class="d-flex justify-end align-center">
                      <v-btn
                        :disabled="
                          !business_details.co_applicant_details.pan_card_file
                        "
                        @click="
                          isImageDocument(
                            business_details.co_applicant_details.pan_card_file
                          )
                            ? previewImage(
                                business_details.co_applicant_details
                                  .pan_card_file,
                                'Aadhaar Front'
                              )
                            : viewDocument(
                                business_details.co_applicant_details
                                  .pan_card_file
                              )
                        "
                        small
                        text
                        class="indigo lighten-5 py-4"
                      >
                        <v-icon color="deep-purple darken-4"
                          >mdi-eye-outline</v-icon
                        >
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row class=" ma-0 document-row mb-2">
                    <v-col cols="4" class=" d-flex align-center">
                      <div class="field-title text-body-2">
                        Selfie
                      </div>
                    </v-col>
                    <v-col class="d-flex justify-end align-center">
                      <v-btn
                        :disabled="
                          !business_details.co_applicant_details
                            .upload_photo_file
                        "
                        small
                        @click="
                          isImageDocument(
                            business_details.co_applicant_details
                              .upload_photo_file
                          )
                            ? previewImage(
                                business_details.co_applicant_details
                                  .upload_photo_file,
                                'Aadhaar Front'
                              )
                            : viewDocument(
                                business_details.co_applicant_details
                                  .upload_photo_file
                              )
                        "
                        text
                        class="indigo lighten-5 py-4"
                      >
                        <v-icon color="deep-purple darken-4"
                          >mdi-eye-outline</v-icon
                        >
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
            <div
              v-else-if="business_details.entity_type == 'company'"
              class="pb-3 px-3 pt-3"
            >
              <v-row class="pl-3 pt-0">
                <v-col cols="4">
                  <p class="field-title">
                    Company Name
                  </p>
                  <p class="field-content">
                    {{
                      this.business_details.co_applicant_details.company_name
                    }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="field-title">
                    Business Address Details
                  </p>
                  <p class="field-content">
                    {{
                      this.business_details.co_applicant_details.address_details
                    }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="field-title">
                    Total no. of Directors
                  </p>
                  <p class="field-content">
                    {{
                      this.business_details.co_applicant_details
                        .total_numbers_of_partners
                    }}
                  </p>
                </v-col>
              </v-row>
              <v-row class="pl-3 pt-0">
                <v-col cols="4">
                  <p class="field-title">
                    Name Of Director 2
                  </p>
                  <p class="field-content">
                    {{
                      checkForValue(
                        this.business_details.co_applicant_details
                          .name_of_director_2
                      )
                    }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="field-title">
                    Role Of Director 2
                  </p>
                  <p class="field-content">
                    {{
                      checkForValue(
                        this.business_details.co_applicant_details
                          .role_of_director_2
                      )
                    }}
                  </p>
                </v-col>
              </v-row>
              <v-row class="pl-3 pt-0">
                <v-col cols="4">
                  <p class="field-title">
                    Name Of Director 3
                  </p>
                  <p class="field-content">
                    {{
                      checkForValue(
                        this.business_details.co_applicant_details
                          .name_of_director_3
                      )
                    }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="field-title">
                    Role Of Director 3
                  </p>
                  <p class="field-content">
                    {{
                      checkForValue(
                        this.business_details.co_applicant_details
                          .role_of_director_3
                      )
                    }}
                  </p>
                </v-col>
              </v-row>
              <v-row class="pl-3 pt-0">
                <v-col cols="4">
                  <p class="field-title">
                    Name Of Director 4
                  </p>
                  <p class="field-content">
                    {{
                      checkForValue(
                        this.business_details.co_applicant_details
                          .name_of_director_4
                      )
                    }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="field-title">
                    Role Of Director 4
                  </p>
                  <p class="field-content">
                    {{
                      checkForValue(
                        this.business_details.co_applicant_details
                          .role_of_director_4
                      )
                    }}
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-row class=" ma-0 document-row">
                    <v-col cols="4" class="d-flex align-center">
                      <div class="field-title text-body-2">
                        Aadhaar Front & Back
                      </div>
                    </v-col>
                    <v-col class="d-flex justify-end align-center">
                      <v-btn
                        :disabled="
                          !business_details.co_applicant_details
                            .aadhar_card_front_and_back
                        "
                        @click="
                          isImageDocument(
                            business_details.co_applicant_details
                              .aadhar_card_front_and_back
                          )
                            ? previewImage(
                                business_details.co_applicant_details
                                  .aadhar_card_front_and_back,
                                'Aadhaar Front & Back'
                              )
                            : viewDocument(
                                business_details.co_applicant_details
                                  .aadhar_card_front_and_back
                              )
                        "
                        small
                        text
                        class="indigo lighten-5 py-4"
                      >
                        <v-icon color="deep-purple darken-4"
                          >mdi-eye-outline</v-icon
                        >
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row class=" ma-0 document-row">
                    <v-col cols="4" class="d-flex align-center">
                      <div class="field-title text-body-2">
                        Aadhaar Front
                      </div>
                    </v-col>
                    <v-col class="d-flex justify-end align-center">
                      <v-btn
                        :disabled="
                          !business_details.co_applicant_details
                            .aadhar_card_front_file
                        "
                        @click="
                          isImageDocument(
                            business_details.co_applicant_details
                              .aadhar_card_front_file
                          )
                            ? previewImage(
                                business_details.co_applicant_details
                                  .aadhar_card_front_file,
                                'Aadhaar Front'
                              )
                            : viewDocument(
                                business_details.co_applicant_details
                                  .aadhar_card_front_file
                              )
                        "
                        small
                        text
                        class="indigo lighten-5 py-4"
                      >
                        <v-icon color="deep-purple darken-4"
                          >mdi-eye-outline</v-icon
                        >
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row class=" ma-0 document-row">
                    <v-col cols="4" class="d-flex align-center">
                      <div class="field-title text-body-2">
                        Aadhaar Back
                      </div>
                    </v-col>
                    <v-col class="d-flex justify-end align-center">
                      <v-btn
                        :disabled="
                          !business_details.co_applicant_details
                            .aadhar_card_back_file
                        "
                        @click="
                          isImageDocument(
                            business_details.co_applicant_details
                              .aadhar_card_back_file
                          )
                            ? previewImage(
                                business_details.co_applicant_details
                                  .aadhar_card_back_file,
                                'Aadhaar Front'
                              )
                            : viewDocument(
                                business_details.co_applicant_details
                                  .aadhar_card_back_file
                              )
                        "
                        small
                        text
                        class="indigo lighten-5 py-4"
                      >
                        <v-icon color="deep-purple darken-4"
                          >mdi-eye-outline</v-icon
                        >
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row class=" ma-0 document-row">
                    <v-col cols="4" class="d-flex align-center">
                      <div class="field-title text-body-2">
                        PAN Card
                      </div>
                    </v-col>
                    <v-col class="d-flex justify-end align-center">
                      <v-btn
                        :disabled="
                          !business_details.co_applicant_details.pan_card_file
                        "
                        @click="
                          isImageDocument(
                            business_details.co_applicant_details.pan_card_file
                          )
                            ? previewImage(
                                business_details.co_applicant_details
                                  .pan_card_file,
                                'Aadhaar Front'
                              )
                            : viewDocument(
                                business_details.co_applicant_details
                                  .pan_card_file
                              )
                        "
                        small
                        text
                        class="indigo lighten-5 py-4"
                      >
                        <v-icon color="deep-purple darken-4"
                          >mdi-eye-outline</v-icon
                        >
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row class=" ma-0 document-row mb-2">
                    <v-col cols="4" class=" d-flex align-center">
                      <div class="field-title text-body-2">
                        Selfie
                      </div>
                    </v-col>
                    <v-col class="d-flex justify-end align-center">
                      <v-btn
                        small
                        :disabled="
                          !business_details.co_applicant_details
                            .upload_photo_file
                        "
                        @click="
                          isImageDocument(
                            business_details.co_applicant_details
                              .upload_photo_file
                          )
                            ? previewImage(
                                business_details.co_applicant_details
                                  .upload_photo_file,
                                'Aadhaar Front'
                              )
                            : viewDocument(
                                business_details.co_applicant_details
                                  .upload_photo_file
                              )
                        "
                        text
                        class="indigo lighten-5 py-4"
                      >
                        <v-icon color="deep-purple darken-4"
                          >mdi-eye-outline</v-icon
                        >
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
            <div
              v-else-if="
                business_details.entity_type == 'LLP' ||
                  business_details.entity_type == 'partnership'
              "
              class="pb-3 px-3 pt-3"
            >
              <v-row class="pl-3 pt-0">
                <v-col cols="4">
                  <p class="field-title">
                    Company Name
                  </p>
                  <p class="field-content">
                    {{ this.business_details.co_applicant_details.firm_name }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="field-title">
                    Co-Applicant Name
                  </p>
                  <p class="field-content">
                    {{
                      this.business_details.co_applicant_details
                        .co_applicant_name
                    }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="field-title">
                    Co-Applicant DOB
                  </p>
                  <p class="field-content">
                    {{
                      this.business_details.co_applicant_details
                        .co_applicant_dob
                    }}
                  </p>
                </v-col>
                <!-- <v-col cols="4">
                  <p class="field-title">
                    Total no. of partners
                  </p>
                  <p class="field-content">
                    {{
                      this.business_details.co_applicant_details
                        .total_numbers_of_partners
                    }}
                  </p>
                </v-col> -->
              </v-row>
              <v-row class="pl-3 pt-0">
                <v-col cols="4">
                  <p class="field-title">
                    Enter No. of Partners
                  </p>
                  <p class="field-content">
                    {{
                      this.business_details.co_applicant_details
                        .total_numbers_of_partners
                    }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="field-title">
                    Name Of Partner 3
                  </p>
                  <p class="field-content">
                    {{
                      this.business_details.co_applicant_details
                        .name_of_partner_3
                    }}
                  </p>
                </v-col>
                <v-col cols="4">
                  <p class="field-title">
                    Name Of Partner 4
                  </p>
                  <p class="field-content">
                    {{
                      this.business_details.co_applicant_details
                        .name_of_partner_4
                    }}
                  </p>
                </v-col>
              </v-row>
              <v-row class="pl-3 pt-0">
                <v-col cols="12">
                  <p class="field-title">
                    Address
                  </p>
                  <p class="field-content">
                    {{
                      this.business_details.co_applicant_details.address_details
                    }}
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-row class=" ma-0 document-row">
                    <v-col cols="4" class="d-flex align-center">
                      <div class="field-title text-body-2">
                        Aadhaar Front & Back
                      </div>
                    </v-col>
                    <v-col class="d-flex justify-end align-center">
                      <v-btn
                        :disabled="
                          !business_details.co_applicant_details
                            .aadhar_card_front_and_back
                        "
                        @click="
                          isImageDocument(
                            business_details.co_applicant_details
                              .aadhar_card_front_and_back
                          )
                            ? previewImage(
                                business_details.co_applicant_details
                                  .aadhar_card_front_and_back,
                                'Aadhaar Front & Back'
                              )
                            : viewDocument(
                                business_details.co_applicant_details
                                  .aadhar_card_front_and_back
                              )
                        "
                        small
                        text
                        class="indigo lighten-5 py-4"
                      >
                        <v-icon color="deep-purple darken-4"
                          >mdi-eye-outline</v-icon
                        >
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row class=" ma-0 document-row">
                    <v-col cols="4" class="d-flex align-center">
                      <div class="field-title text-body-2">
                        Aadhaar Front
                      </div>
                    </v-col>
                    <v-col class="d-flex justify-end align-center">
                      <v-btn
                        :disabled="
                          !business_details.co_applicant_details
                            .aadhar_card_front_file
                        "
                        @click="
                          isImageDocument(
                            business_details.co_applicant_details
                              .aadhar_card_front_file
                          )
                            ? previewImage(
                                business_details.co_applicant_details
                                  .aadhar_card_front_file,
                                'Aadhaar Front'
                              )
                            : viewDocument(
                                business_details.co_applicant_details
                                  .aadhar_card_front_file
                              )
                        "
                        small
                        text
                        class="indigo lighten-5 py-4"
                      >
                        <v-icon color="deep-purple darken-4"
                          >mdi-eye-outline</v-icon
                        >
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row class=" ma-0 document-row">
                    <v-col cols="4" class="d-flex align-center">
                      <div class="field-title text-body-2">
                        Aadhaar Back
                      </div>
                    </v-col>
                    <v-col class="d-flex justify-end align-center">
                      <v-btn
                        :disabled="
                          !business_details.co_applicant_details
                            .aadhar_card_back_file
                        "
                        @click="
                          isImageDocument(
                            business_details.co_applicant_details
                              .aadhar_card_back_file
                          )
                            ? previewImage(
                                business_details.co_applicant_details
                                  .aadhar_card_back_file,
                                'Aadhaar Front'
                              )
                            : viewDocument(
                                business_details.co_applicant_details
                                  .aadhar_card_back_file
                              )
                        "
                        small
                        text
                        class="indigo lighten-5 py-4"
                      >
                        <v-icon color="deep-purple darken-4"
                          >mdi-eye-outline</v-icon
                        >
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row class=" ma-0 document-row">
                    <v-col cols="4" class="d-flex align-center">
                      <div class="field-title text-body-2">
                        PAN Card
                      </div>
                    </v-col>
                    <v-col class="d-flex justify-end align-center">
                      <v-btn
                        :disabled="
                          !business_details.co_applicant_details.pan_card_file
                        "
                        @click="
                          isImageDocument(
                            business_details.co_applicant_details.pan_card_file
                          )
                            ? previewImage(
                                business_details.co_applicant_details
                                  .pan_card_file,
                                'Aadhaar Front'
                              )
                            : viewDocument(
                                business_details.co_applicant_details
                                  .pan_card_file
                              )
                        "
                        small
                        text
                        class="indigo lighten-5 py-4"
                      >
                        <v-icon color="deep-purple darken-4"
                          >mdi-eye-outline</v-icon
                        >
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row class=" ma-0 document-row mb-2">
                    <v-col cols="4" class=" d-flex align-center">
                      <div class="field-title text-body-2">
                        Selfie
                      </div>
                    </v-col>
                    <v-col class="d-flex justify-end align-center">
                      <v-btn
                        small
                        :disabled="
                          !business_details.co_applicant_details
                            .upload_photo_file
                        "
                        @click="
                          isImageDocument(
                            business_details.co_applicant_details
                              .upload_photo_file
                          )
                            ? previewImage(
                                business_details.co_applicant_details
                                  .upload_photo_file,
                                'Aadhaar Front'
                              )
                            : viewDocument(
                                business_details.co_applicant_details
                                  .upload_photo_file
                              )
                        "
                        text
                        class="indigo lighten-5 py-4"
                      >
                        <v-icon color="deep-purple darken-4"
                          >mdi-eye-outline</v-icon
                        >
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
            <div v-else class="d-flex align-center justify-center">
              <div class="my-4">
                No Details Available
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <edit-business-detail
      :business_dialog="business_detail_dialog"
      @reload="getBusinessDetails"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import editBusinessDetail from "../dialog/editBusinessDetail.vue";

export default {
  components: {
    // LoanTable: () => import("@/components/customer/LoanTable"),
    editBusinessDetail,
  },
  data() {
    return {
      //   business_accordion_details: {
      //     id: 1,
      //     title: "Basic Details",
      //     show: true,
      //   },

      are_all_collapsed: false,

      active_loan_id: null,
      is_other_doc_required: false,
      is_other_doc_verified: false,
      is_active_loan_available: false,
      business_details: [],
      co_applicant_details: [],
      business_detail_dialog: {
        flag: false,
        business_detail: {},
        id: "",
        co_applicant_details: {},
      },
    };
  },
  computed: {
    ...mapGetters({
      business_accordion_details: "business_loan/getBusinessDetailsAccordion",
    }),
  },
  mounted() {
    this.getBusinessDetails();
  },
  methods: {
    checkForValue(value) {
      return value ? value : "-";
    },
    toggleBasicDetailsAccordion() {
      //   this.business_accordion_details.show = !this.business_accordion_details.show;
      this.$store.dispatch("business_loan/setBusinessDetailsAccordion");
      //   console.log(this.business_accordion_details);
    },
    viewDocument(url) {
      if (url) window.open(url, "_blank");
    },
    // returning true if image extension is png, jpg or, jpeg
    isImageDocument(doc) {
      // console.log(doc);
      if (doc) {
        let ext = doc.split(".");
        ext = ext[ext.length - 1];
        console.log(ext);
        return ext == "png" || ext == "jpg" || ext == "jpeg";
      } else {
        this.showSnakeBar("error", "No document available");
      }
    },
    // if isImageDocument returns true then opens image in new tab
    previewImage(doc, document_type) {
      let image_url = doc;

      let route = this.$router.resolve({
        name: "view-image",
        params: {
          image: this.encript(image_url),
          document: this.encript(document_type),
        },
      });
      window.open(route.href, "_blank");
    },
    getBusinessDetails() {
      const self = this;
      let look_up_key = `${self.decrypt(this.$route.params.id)}/`;
      const successHandler = (response) => {
        // console.log("business", response);
        this.business_details = response.data.result;
        this.co_applicant_details = response.data.result.co_applicant_details;
        // console.log("entity type", this.business_details.entity_type);
      };
      const finallyHandler = () => {};
      self.GET_request(
        self,
        self.$urls.GET_BUSINESS_LOAN_BUSINESS_DETAILS,
        look_up_key,
        null,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    editBusinessDetail() {
      this.business_detail_dialog.business_detail = this.business_details;
      this.business_detail_dialog.flag = true;
      this.business_detail_dialog.id = this.decrypt(this.$route.params.id);
      this.business_detail_dialog.co_applicant_details = this.co_applicant_details;
    },
  },
};
</script>
<style scoped>
.field-title {
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.681);
}
.field-content {
  font-size: 14px;
  color: #000000;
  font-weight: 700;
}
</style>
